import { AsyncPipe, NgIf } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Signal,
  signal,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNil } from 'lodash-es';
import { map, Observable } from 'rxjs';

import { CONTEXTUAL_TUTORIAL_SERVICE, ContextualTutorialBaseService } from '@stsm/contextual-tutorial';
import { GlobalElementId } from '@stsm/global/models/enums/element-id';
import { TranslatePipe } from '@stsm/i18n/global/pipes/translate.pipe';
import { Studyset } from '@stsm/studysets/models/studyset';
import { TagInputComponent } from '@stsm/tags/feature/components/tag-input/tag-input.component';
import { TagManagementDialogService } from '@stsm/tags/feature/components/tag-management-dialog/tag-management-dialog.service';
import { FlashcardTag, Tag, TagContentFilter, TagData } from '@stsm/tags/models/tag';
import { TagsStoreFacade } from '@stsm/tags/store/tags-store-facade.service';
import { ButtonComponent } from '@stsm/ui-components/button';
import { DialogRef } from '@stsm/ui-components/dialogs/models/dialog-ref';
import { TutorialId } from '@stsm/user/models/frontend-settings';

@UntilDestroy()
@Component({
  selector: 'app-flashcard-tags-dialog',
  templateUrl: './flashcard-tags-dialog.component.html',
  styleUrls: ['./flashcard-tags-dialog.component.scss'],
  imports: [AsyncPipe, ButtonComponent, ReactiveFormsModule, TagInputComponent, TranslatePipe, NgIf],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { ['data-cy']: 'flashcard-tags-dialog' },
})
export class FlashcardTagsDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(TagInputComponent) tagInput?: TagInputComponent;

  @Input({ required: true })
  studyset!: Studyset;

  @Input()
  tags: FlashcardTag[] = [];

  @Input()
  isBulkEdit: boolean = false;

  form: FormGroup<{ tags: FormControl<Tag[]> }> = new FormGroup<{ tags: FormControl<Tag[]> }>({
    tags: new FormControl<Tag[]>([], { nonNullable: true }),
  });

  allParentTags$!: Observable<Tag[]>;

  isTagManagementAvailable: Signal<boolean> = signal(false);

  protected readonly TagContentFilter: typeof TagContentFilter = TagContentFilter;
  protected readonly GlobalElementId: typeof GlobalElementId = GlobalElementId;

  private readonly _tutorialId: TutorialId = 'tutorial_tag_management';

  constructor(
    private readonly _dialogRef: DialogRef<FlashcardTagsDialogComponent>,
    private readonly _tagsStoreFacade: TagsStoreFacade,
    private readonly _tagManagementDialogService: TagManagementDialogService,
    @Inject(CONTEXTUAL_TUTORIAL_SERVICE) private readonly _contextualTutorialService: ContextualTutorialBaseService,
  ) {}

  ngOnInit(): void {
    this.form.patchValue({ tags: this.tags });

    this.allParentTags$ = this._tagsStoreFacade
      .tags(this.studyset.id, TagContentFilter.FLASHCARDS)
      .pipe(map((tagData: TagData) => tagData.allParentTags));

    this.isTagManagementAvailable = this._tagManagementDialogService.isTagManagementAvailable(this.studyset.id);
  }

  ngAfterViewInit(): void {
    void this.tagInput?.focus();

    if (this.isTagManagementAvailable()) {
      this._contextualTutorialService.start(this._tutorialId);
      void this._contextualTutorialService.markCompleted(this._tutorialId);
    }
  }

  ngOnDestroy(): void {
    this._contextualTutorialService.dispose(this._tutorialId);
  }

  onSubmit(): void {
    const selectedTagIds = this.form.getRawValue().tags.map((tag: Tag) => tag.id);

    if (!isNil(this.tagInput)) {
      this.tagInput
        .checkAndCreate()
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this._dialogRef.dismiss(selectedTagIds);
        });
    }
  }

  openTagManagementDialog(event: MouseEvent): void {
    event.preventDefault();

    this._tagManagementDialogService.openTagManagementDialog(this.studyset.id, 'add_tag_dialog');

    void this._contextualTutorialService.markCompleted(this._tutorialId);
  }
}
